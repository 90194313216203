import { isParagraph } from 'datocms-structured-text-utils';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useContext } from 'react';
import { StructuredText, renderRule } from 'react-datocms';
import { Locale } from '../context/Locale';
import Layout from '../layout/index';
import { NavigationButtons } from '../utils/cleanProps';

import { ButtonV2 } from 'libs/growth-platform-brand-system-v2/src/components/ButtonV2';
import { Section } from 'libs/growth-platform-brand-system-v2/src/components/Section';
import {
  textSerifTitle1,
  textTitle3,
} from 'libs/growth-platform-brand-system-v2/src/styles/typography';
import { NavigationButtonsSection } from 'libs/growth-platform-brand-system-v2/src/templates/NavigationButtonsSection';
import { classNames } from 'libs/growth-platform-brand-system-v2/src/utils/style';
import { ButtonTracking } from 'libs/growth-platform-brand-system-v2/src/utils/tracking/ButtonTracking';
import { useScreenSize } from 'libs/growth-platform-brand-system-v2/src/utils/utils';
import { morphism } from 'morphism';
import { schemaDatoButton } from '../morphismSchema/schemaDatoButton';
import './styles.scss';

const NotFoundPage = () => {
  const screenSize = useScreenSize();

  const data = useStaticQuery(graphql`
    query NotFoundQuery {
      fr: datoCmsNotFoundPage(locale: "fr") {
        ...NotFoundPage
      }
      en: datoCmsNotFoundPage(locale: "en") {
        ...NotFoundPage
      }
      es: datoCmsNotFoundPage(locale: "es") {
        ...NotFoundPage
      }
    }
  `);
  const { locale }: any = useContext(Locale);
  const doc = data[locale];
  if (!doc) return null;

  const cta404 = morphism(schemaDatoButton, {
    ...doc.button,
    screenSize,
    locale,
  });
  return (
    <Layout pageContext={{ locale }} data={doc}>
      <section className="container flex flex-col items-center hero-padding not-found">
        <h1
          className={classNames(
            textSerifTitle1,
            'mb-24 text-center text-neutral-301 md:w-8/12 title',
          )}
        >
          {doc.title}
        </h1>
        <div className="mb-24 md:w-1/2">
          <StructuredText
            data={doc.subtitle}
            customNodeRules={[
              renderRule(isParagraph, ({ children, key }) => (
                <p
                  key={key}
                  className={classNames(
                    textTitle3,
                    'text-neutral-101 text-center',
                  )}
                >
                  {children}
                </p>
              )),
            ]}
          />
        </div>
        {doc.button && (
          <ButtonTracking
            component={ButtonV2}
            {...cta404}
            labelTracking={cta404.title}
          />
        )}

        <div style={{ height: 200, width: 200 }} className="mt-48">
          {'gatsbyImageData' in doc.illustration && (
            <GatsbyImage
              alt="404-illustration"
              image={doc.illustration?.gatsbyImageData}
            />
          )}
        </div>
      </section>
      {doc.blocks.map((item: any, key: number) => {
        return (
          <Section backgroundColor="blue" key={key}>
            {item?.__typename === 'DatoCmsNavigationButtonsList' && (
              <NavigationButtonsSection
                title={item?.title || undefined}
                subtitle={item?.subtitle || undefined}
                backgroundColor="blue"
                navigationButtons={
                  <NavigationButtons
                    navigationButtons={item?.navigationButtons}
                    locale={locale}
                  />
                }
              />
            )}
          </Section>
        );
      })}
    </Layout>
  );
};

export default NotFoundPage;
